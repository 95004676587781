.envelope-þing {
  $þingmen: bottom, content, window, top;

  @include envelope-layer;

  .envelope-bottom-þing{ z-index: index($þingmen, bottom); }
  .envelope-content-þing{ z-index: index($þingmen, content); }
  .envelope-top-þing{ z-index: index($þingmen, top); }
  .envelope-window-þing{ z-index: index($þingmen, window); }

}