.overlay{
  align-items: center;
  background-color: $back;
  display: none;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 32px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100vw;
  z-index: 100;

  h1{
    font-family: "Satisfy" Courier;
  }
}