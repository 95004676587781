.share{
  align-items: center;
  background-color: rgba(255,255,255,0.84);
  display: flex;
  font-size: 10px;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100vw;
  z-index: 100;

  @include breakpoint(md){
    font-size: 14px;
    .pufflavík.large{
      height: 120px;
      width: 120px;
    }
  }

  #payment-request-button{
    margin-top: 30px;
  }

  .pufflavík{
    width: 64px;
    height: 64px;
  }

  &.hide{
    height: 0;
    opacity: 0;
  }

  .share-content{
    padding: 0 32px;
    text-align: center;
    max-width: 600px;
  }

  h1{
    font-family: "Satisfy" Courier;
  }

  a{
    color: #333 !important;
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;

    span{
      text-transform: uppercase;
      text-decoration: underline;
    }

    &.start-over{
      margin-top: 20px;
    }
  }

  form{
    font-weight: bold;
    font-size: 110%;
    text-align: center;
    text-transform: uppercase;
    input{
      background-color: transparent;
      border: none;
      color: #333;
      text-align: center;
      width: 100%;

      &:focus{
        outline-width: 0;
      }
    }
  }

  span{
    text-transform: none;
  }

}