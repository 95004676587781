.telegram-wrapper{
  display: block;
  width: 100vw;
  > div{
    display: inline-block;
    margin: 0 auto;
    text-align: left;
  }
  > .telegram-stað-vaðmál.folded .telegram-stað-telegram-panel-0{
    cursor: pointer;
  }
  text-align: center;
}