:root:before{
  content: url('../img/pufflavik-bashful.gif')
           url('../img/pufflavik-happy.gif');
  display: none;
}

.pufflavík{
  background-image: url('../img/pufflavik-default.gif');
  background-position: center center;
  background-size: contain;
  border-radius: 100%;
  border: 2px solid white;
  box-shadow: 2px 3px 0 rgba(0,0,0,1);
  display: inline-block;
  height: 64px;
  width: 64px;

  @include breakpoint(md){
    height: 84px;
    width: 84px;
    border: 3px solid white;
  }

  &.bashful{
    background-image: url('../img/pufflavik-bashful.gif');
  }

  &.happy{
    background-image: url('../img/pufflavik-happy.gif');
  }

  &.large{
    height: 155px;
    width: 155px;
  }
}