@mixin envelope-window-layer{
  @include envelope-layer;
  
  $aspect-ratio: 0.23;
  $border-radius-horizontal: 2.5%;
  $border-radius-vertical: $border-radius-horizontal / $aspect-ratio;

  border-top-right-radius: $border-radius-horizontal $border-radius-vertical;
  border-top-left-radius: $border-radius-horizontal $border-radius-vertical;
  border-bottom-right-radius: $border-radius-horizontal $border-radius-vertical;
  border-bottom-left-radius: $border-radius-horizontal $border-radius-vertical;
}