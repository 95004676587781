.hud{

  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  transition: transform .2s ease-out;

  &:hover.clickable, &:active.clickable{
    cursor: pointer;
    transform: translateY(-5px);
  }

  &.shake{
    transform: translateY(200%);
  }

  &:active.clickable{
    transform: scale(0.99);
  }

  > *:first-child {
    margin-right: 15px;
  }

  &.hide, &.hide.clickable{
    transform: translateY(100vh);
  }

}