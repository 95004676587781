.telegram-catalog{

  .telegram-vaðmál{
    @include breakpoint(md){
      margin: 32px auto;
    }

    margin: 18px auto;
    
    &:first-child{
      margin-top: 0px;
    }

  }
}