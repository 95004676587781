.catalog-item-view{

  $aspect-ratio: 8 / 6.5;
  $buy-button-offset-bottom: 8%;
  $buy-button-offset-right: $buy-button-offset-bottom / $aspect-ratio;

  position: relative;

  > .telegram-vaðmál{
    margin: 0 auto;
    display: block;

    > .buy-button{
      bottom: $buy-button-offset-bottom;
      position: absolute;
      right: $buy-button-offset-right;
      z-index: 100;
    }
  }

}