* {
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  font-size: 62.5%;
  background-color: $back;
}
body {
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 18%;
  color: #444;
  font: 300 13px/1.6 Courier;
  justify-content: center;
  position: relative;
  transition: all .4s .3s ease-in;
}
body,html{
	min-height: 100vh;
}