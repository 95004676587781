.envelope-top-paper-þing{
  $þingmen: texture, edge, hue, printed, shader;

  @include envelope-layer;
  @include mask('envelope-top-þing-mask.gif');

  .envelope-edge-layer { z-index: index($þingmen, edge) }
  .envelope-hue-layer { z-index: index($þingmen, hue) }
  .envelope-shader-layer{ z-index: index($þingmen, shader); }
  .envelope-texture-layer { z-index: index($þingmen, texture) }
  .envelope-top-print-layer { z-index: index($þingmen, printed) }
}
