.ribbon-button-view{

  $animation-duration: 1s;        // animation duration
  $aspect-ratio: 1.25;            // width : height
  $background-color: #B9503F;     // ribbon background color
  $height: 50px;                  // height of the ribbon's rectangle
  $shadow-color: black;           // color of the ribbon's shadow
  $shadow-offset: 5%;             // shadow offset
  $side-margin: 32px;             // horizontal distance to screen edge
  $side-margin-lg: 25%;           // screen edge margin on large screens
  $width: $height / $aspect-ratio;// width of the ribbon's rectangle

  display: block;
  height: $height * 2;
  position: fixed;
  top: -$height;
  transform: translate3d(0,0,0);
  transition: transform $animation-duration cubic-bezier(.6,-0.36,.37,1.31);
  width: $width;
  z-index: 100;

  // foreground element
  >a{
    background-color: $background-color;
    box-sizing: border-box;
    color: white;
    display: block;
    font-family: 'Pacifico', cursive;
    font-size: $height / 5;
    font-weight: normal;
    height: 100%;
    line-height: $height;
    padding-top: $height;  
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  // shadow element
  >div{
    background-color: $shadow-color;
    height: 100%;
    left: $shadow-offset;
    position: absolute;
    top: $shadow-offset / $aspect-ratio;
    width: 100%;
    z-index: 0;
  }

  >a, >div{
    // ribbon triangles
    &::after{
      $triangle-width: $width / 2;
      border-bottom: $triangle-width * 0.5 solid transparent;
      border-left: $triangle-width solid $background-color;
      border-right: $triangle-width solid $background-color;
      bottom: $triangle-width * -0.5;
      content: "";
      left: 0;
      position: absolute;
    }
  }

  >div::after{
    border-left-color: $shadow-color;
    border-right-color: $shadow-color;
  }

  &.left{
    @include breakpoint(lg){
      left: $side-margin-lg;
    }
    left: $side-margin;
  }

  &.right{
    @include breakpoint(lg){
      right: $side-margin-lg;
    }
    right: $side-margin;
  }

  &.off-screen{
    top: -200%;
  }

  &.hidden{
    transform: translateY(-200%);
  }

  &:hover{
    transition: transform 0.2s;
    transform: translateY(5%);
  }

}