.envelope-bottom-seam-layer {
  $angle: 35deg;
  $hoffset: 23%;
  $voffset: 23%;
  $shadow-opacity: 0.12;
  $shadow-size: 1.5px;

  @include envelope-layer;

  &:before, &:after{
    bottom: $voffset;
    box-shadow: inset 0px 0px $shadow-size $shadow-size rgba(0,0,0,$shadow-opacity);
    content: "";
    height: 50%;
    position: absolute;
    width: 75%;
    z-index: 1;
  }

  &:before{
    left: -$hoffset;
    transform: rotateZ(-$angle);
  }

  &:after{
    right: -$hoffset;
    transform: rotateZ($angle);
  }
}