.envelope-window-þing {
  $þingmen: hue, bump, shader;
  $left: 3.1%;
  $right: $left;
  $top: 61%;
  $bottom: 5.5%;
  $opacity: 0.32;

  bottom: $bottom;
  left: $left;
  position: absolute;
  right: $right;
  top: $top;

  .envelope-window-bump-layer { z-index: index($þingmen, bump) }
  .envelope-window-hue-layer { z-index: index($þingmen, hue) }
  .envelope-window-shader-layer { z-index: index($þingmen, shader) }

  .envelope-window-hue-layer{ opacity: $opacity; }
  .envelope-window-bump-layer{ opacity: $opacity; }
}