.footer {
  @include breakpoint(md){
    position: fixed;
    bottom: 40px;
  }

  bottom: 60px;
  display: flex;
  flex-direction: row;
  height: $footer-height;
  justify-content: center;
  overflow: visible;
  padding-top: 5px;
  pointer-events: none;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 10;

  * {
    pointer-events: auto;
  }

}
