.share-poster{
  $color-dark: black;
  $color-light: #F8F4EE;
  $color-loud: #E3340C;
  $color-primary: #84BCDE;

  $desktop-scaler: 2;

  $font-family-headline: 'Pacifico', cursive;
  $font-family-body: serif;

  $font-size-headline: 36px;

  $scaler: 2;
  $spacer-base: 10px;

  $spacer-sm: $spacer-base;
  $spacer-md: $spacer-base * $scaler;
  $spacer-lg: $spacer-base * $scaler * $scaler;
  $spacer-xl: $spacer-base * $scaler * $scaler * $scaler;

  $text-color: $color-dark;
  $text-color-inverted: $color-light;
  $text-color-loud: $color-loud;

  display: block;
  background: $color-light;
  margin: $spacer-sm;
  padding: $spacer-sm;
  max-width: 700px;

  @include breakpoint(md){
    padding: $spacer-md;
    margin: $spacer-md auto;
  }


  h1{
    @include breakpoint(md){
      font-size: $font-size-headline * $desktop-scaler;
    }
    text-align: center;
    font-family: $font-family-headline;
    font-size: $font-size-headline;
    color: $color-light;
    margin: 0;
  }

  .share-poster-section{
    @include breakpoint(md){
      padding: $spacer-md * $desktop-scaler;
    }

    display: block;
    padding: $spacer-md;

    &:first-child, &:last-child{
      background: $color-primary;
    }

    &:nth-child(2){
      position: relative; 

      .envelope-vaðmál{
        position: relative;
        display: block;
        margin: 0 auto;
        z-index: 1;
      }

      .telegram-vaðmál{
        z-index: 1;
      }

      .dodecagon-button{
        bottom: 32px;
        position: absolute;
        right: 32px;
        z-index: 2;
      }

      .envelope-content-þing{
        overflow: hidden;
      }

      .telegram-vaðmál{
        margin: 0 auto;
      }
    }

  }


}