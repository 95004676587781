.buy-button{
  $aspect-ratio: 1 / 1;
  $desktop-zoom: 1.25;
  $height: 64px;
  $hover-transform-distance: -5%;
  $shadow-offset: 5%;
  $transition-time: 0.5s;
  $width: $height / $aspect-ratio;

  @include breakpoint(md){
    height: $height * $desktop-zoom;
    width: $width * $desktop-zoom;
  }

  border: none;
  display: block;
  height: $height;
  position: relative;
  transition: transform $transition-time;
  width: $width;

  &::before, &::after{
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &::before{
    left: 0;
    top: 0;
    z-index: 1;
  }

  &::after{
    background-image: url("../img/buy-btn_shadow.gif");
    left: $shadow-offset;
    top: $shadow-offset;
    z-index: -1;
  }

  &:hover{
    cursor: pointer;
    transform: translateY($hover-transform-distance);
  }

  &:active{
    transition: none;
    transform: translateY(0);
  }

  &.buy-button-50::before{
    background-image: url("../img/buy-btn-50.gif");
  }

  &.buy-button-0::before{
    background-image: url("../img/buy-btn-0.gif");
  }

}