.envelope-content-þing {
  @include envelope-layer;
  
  height: 95%;
  width: 98%;
  left: 1%;
  top: 1%;

  > * {
    position: absolute !important;
  }
}