@mixin breakpoint($class) {

  // Small tablets and large smartphones (landscape view)
  $screen-sm-min: 576px;

  // Small tablets (portrait view)
  $screen-md-min: 768px;

  // Tablets and small desktops
  $screen-lg-min: 992px;

  // Large tablets and desktops
  $screen-xl-min: 1200px;

  @if $class == xs {
     @media (max-width: $screen-sm-min) { @content ; }
  }
  @else if $class == sm {
     @media (min-width: $screen-sm-min) { @content ; }
  }
  @else if $class == md {
     @media (min-width: $screen-md-min) { @content ; }
  }
  @else if $class == lg {
     @media (min-width: $screen-lg-min)  { @content ; }
  }
  @else if $class == xl {
     @media (max-width: $screen-xl-min)  { @content ; }
  }
}