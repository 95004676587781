.callout {

  background-color: #fff;
  border-radius: 0;
  box-shadow: 3px 3px 0 rgba(0,0,0,1);
  display: inline-block;
  font-size: 10px;
  margin-left: 15px;
  padding: 8px 15px;
  position: relative;
  text-align: left;

  @include breakpoint(md){
    font-size: 14px;
  }

  strong{
    font-weight: bold;
  }

  &:after, &:before{
    border-color: transparent #fff;
    border-style: solid;
    border-width: 9px 15px 9px 0;
    content: "";
    display: block;
    left: -15px;
    position: absolute;
    top: 8px;
    width: 0;
  }
  &:before {
    border-color: transparent rgba(0,0,0,1);
    top: 13px;
    left: -12px;
    z-index: -1;
  }
}